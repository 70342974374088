// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-discount-calculator-js": () => import("./../../../src/pages/price-discount-calculator.js" /* webpackChunkName: "component---src-pages-price-discount-calculator-js" */),
  "component---src-pages-sales-tax-calculator-js": () => import("./../../../src/pages/sales-tax-calculator.js" /* webpackChunkName: "component---src-pages-sales-tax-calculator-js" */),
  "component---src-pages-stock-options-calculator-js": () => import("./../../../src/pages/stock-options-calculator.js" /* webpackChunkName: "component---src-pages-stock-options-calculator-js" */),
  "component---src-pages-success-rate-calculator-js": () => import("./../../../src/pages/success-rate-calculator.js" /* webpackChunkName: "component---src-pages-success-rate-calculator-js" */),
  "component---src-pages-tip-calculator-js": () => import("./../../../src/pages/tip-calculator.js" /* webpackChunkName: "component---src-pages-tip-calculator-js" */)
}

